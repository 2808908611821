import { CartProvider, ThemeProvider } from '@boost-sd/app';
import { createRoot } from 'react-dom/client';

export const initCart = () => {
  const cartContainerId = 'boost-sd__cart-container';
  const cartContainer = document.createElement('div');
  cartContainer.setAttribute('id', cartContainerId);
  document.body.appendChild(cartContainer);

  const root = createRoot(cartContainer);

  const updateCartIconHeader = (cartItemCount: number) => {
    const cartIconHeaderWrapper = document.querySelector('.header__icon--cart');

    if (!cartIconHeaderWrapper) return;

    document.querySelector('.cart-count-bubble')?.remove();

    if (cartItemCount === 0) return;

    const cartCountEl = document.createElement('div');
    cartCountEl.className = 'cart-count-bubble';
    cartCountEl.innerHTML = `<span aria-hidden="true">${cartItemCount}</span>
  <span class="visually-hidden">${cartItemCount} item</span>`;

    cartIconHeaderWrapper.append(cartCountEl);
  };

  root.render(
    <ThemeProvider>
      <CartProvider
        cartIconHeaderSelector='.header__icon--cart'
        onUpdateCart={updateCartIconHeader}
      />
    </ThemeProvider>
  );
};
