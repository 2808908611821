import './Tooltip.scss';

import type { ModifiersType } from '@utils';
import { appendClassNames, createClsNameMap, mergeModifiers } from '@utils';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    arrow: createClsNameMap({}),
    content: createClsNameMap({}),
  },
  modifiers: ['filter-tree'],
})('tooltip');

export type TooltipProps = {
  className?: string;
  content: string | React.ReactElement;
  hasArrow?: boolean;
  modifiers?: ModifiersType<typeof clsNameMap, true>;
  disabled?: boolean;
};

const Tooltip = ({
  className,
  content,
  hasArrow = false,
  modifiers,
  children,
  disabled,
}: PropsWithChildren<TooltipProps>) => {
  const tooltipContent = useRef<HTMLDivElement>(null);
  const tooltipArrow = useRef<HTMLSpanElement>(null);
  const rootElementRef = useRef<HTMLDivElement>(null);

  const adjustTooltipPosition = () => {
    if (!tooltipContent.current || !rootElementRef.current) return;

    const position = rootElementRef.current.getBoundingClientRect();

    // TODO: Created PFSN-48717 - Update later
    const slideAsParent = tooltipContent.current.closest('.slick-slide');

    if (slideAsParent) {
      const { height: tooltipHeight, y: tooltipY } = rootElementRef.current.getBoundingClientRect();
      const { y: slideY } = slideAsParent.getBoundingClientRect();
      const transformY = tooltipY - slideY < tooltipHeight ? '35px' : '-35px';

      let transform = `translate(calc(-50% + ${position.width / 2}px), ${transformY})`;

      Object.assign(tooltipContent.current.style, {
        position: 'fixed',
        display: 'block',
        transform,
      });

      const { width: tooltipWidth, x: tooltipX } = tooltipContent.current.getBoundingClientRect();

      if (slideAsParent.classList.contains('slick-current')) {
        const { x: slideX } = slideAsParent.getBoundingClientRect();
        if (tooltipX - slideX < tooltipWidth) {
          transform = `translate(0px, ${transformY})`;
        }
      }

      if (
        slideAsParent.classList.contains('slick-active') &&
        !slideAsParent.nextElementSibling?.classList.contains('slick-active')
      ) {
        const { x: slideX, width: slideWidth } = slideAsParent.getBoundingClientRect();
        if (slideX + slideWidth - tooltipX < tooltipWidth) {
          transform = `translate(-50%, ${transformY})`;
        }
      }

      Object.assign(tooltipContent.current.style, {
        position: 'fixed',
        display: 'block',
        transform,
      });

      return;
    }

    let left = `${position.left}px`;
    const transform = `translate(calc(-50% + ${position.width / 2}px), calc(-100% - 6px))`;

    Object.assign(tooltipContent.current.style, {
      position: 'fixed',
      left,
      top: `${position.top}px`,
      display: 'block',
      transform,
    });

    const positionContent = tooltipContent.current?.getBoundingClientRect();

    if (positionContent.width / 2 > position.left) {
      left = `${positionContent.width / 2}px`;
      Object.assign(tooltipContent.current.style, {
        left,
      });
    }
  };

  useEffect(() => {
    const hideTooltipOnEvent = () => {
      if (tooltipContent.current) {
        Object.assign(tooltipContent.current.style, {
          display: 'none',
        });
      }
    };

    window.addEventListener('scroll', hideTooltipOnEvent, true);
    window.addEventListener('resize', hideTooltipOnEvent, true);

    return () => {
      window.removeEventListener('scroll', hideTooltipOnEvent, true);
      window.removeEventListener('resize', hideTooltipOnEvent, true);
    };
  }, []);

  return (
    <div
      ref={rootElementRef}
      onMouseEnter={adjustTooltipPosition}
      onMouseLeave={() => {
        if (tooltipContent.current) {
          Object.assign(tooltipContent.current.style, {
            display: 'none',
          });
        }
      }}
      className={appendClassNames(mergeModifiers(clsNameMap, [modifiers]), className)}
    >
      {!disabled && (
        <div ref={tooltipContent} className={clsNameMap.elm('content')}>
          {content}
          {hasArrow && <span ref={tooltipArrow} className={clsNameMap.elm('arrow')}></span>}
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
