import './QuickView.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import FormatCurrency from '@components/FormatCurrency';
import IntegrationProductRating from '@components/IntegrationProductRating';
import Modal from '@components/Modal';
import ProductImage from '@components/ProductImage';
import ProductLabel from '@components/ProductLabel';
import TranslateWithComponent from '@components/ProductPrice/TranslateWithComponent';
import ProductSwatch from '@components/ProductSwatch';
import Quantity from '@components/Quantity';
import QuickViewButton from '@components/QuickViewButton';
import Slider from '@components/Slider';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import type { AddToCartState } from '@providers/CartProvider';
import type { ProductItemMetaField } from '@providers/ProductListProvider';
import { useProductItemThemeSettings } from '@providers/ThemeProvider/Provider/ProductItemThemeSettings';
import { useQuickViewThemeSettings } from '@providers/ThemeProvider/Provider/QuickViewThemeSettings';
import type { Dict } from '@types';
import {
  buildProductDetailUrlWithVariant,
  createClsNameMap,
  isMobileWidth,
  mapModifiers,
} from '@utils';
import classnames from '@utils/classnames';
import { isEqual, keyBy, keys, mapValues, update } from 'lodash-es';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { renderToString } from 'react-dom/server';

import { useConnectProductLabel } from '../ProductItem/connectors/connectProductLabel';
import { useConnectQuickViewButton } from './connectors/connectQuickViewButton';

const clsNameMap = createClsNameMap({
  elements: {
    price: createClsNameMap(),
    quantity: createClsNameMap(),
    'quantity-title': createClsNameMap(),
    'product-info': createClsNameMap({
      modifiers: ['in-qv-mini'],
    }),
    'details-link': createClsNameMap(),
    'product-image': createClsNameMap(),
    title: createClsNameMap(),
    vendor: createClsNameMap(),
    description: createClsNameMap(),
    btn: createClsNameMap(),
  },
})('quick-view');

export type ProductDataVariant = {
  available: boolean;
  barcode?: string;
  compare_at_price?: string | null;
  fullfillment_service?: string;
  id: string;
  image?: string | null;
  inventory_management?: string;
  inventory_policy?: string;
  inventory_quantity?: number;
  merged_options: Array<string>;
  original_merged_options?: Array<string>;
  price?: string;
  sku?: string;
  title?: string;
};

export type ProductDataOption = {
  label: string;
  name: string;
  original_name?: string;
  values: Array<{
    image: number | null;
    title: string;
    origin_title?: string;
  }>;
};

export type QuickViewProductData = {
  id: number;
  variant_id?: number;
  body_html?: string;
  images: { [key: string]: string };
  original_images?: Array<{
    alt?: string;
    height?: number;
    id?: number;
    position?: number;
    src?: string;
    width?: number;
  }>;
  images_info: Array<{
    alt: string;
    height: number;
    id: number;
    position: number;
    src: string;
    width: number;
  }>;
  title: string;
  original_title?: string;
  vendor: string;
  options_with_values: Array<ProductDataOption>;
  variants: Array<ProductDataVariant>;
  metafields?: Array<ProductItemMetaField>;
  skus?: string[];
  tags?: string[];
  handle: string;
  available?: boolean;
  compare_at_price_min?: number | null;
  compare_at_price_max?: number | null;
  price_min?: number;
};

export type QuickViewProps = {
  isOpen?: boolean;
  isMini?: boolean;
  productData?: QuickViewProductData;
  timeToShowResultAddToCart?: number;
  handleCloseModalQuickView: () => void;
  callbackFuncAppIntegration?: (() => void)[];
  onRender?: ReactNodeRenderer<QuickViewProductData | undefined>;
};

const QuickView = ({
  isOpen,
  isMini,
  productData,
  handleCloseModalQuickView,
  timeToShowResultAddToCart = 350,
  callbackFuncAppIntegration,
  onRender,
}: QuickViewProps) => {
  const { t } = useTranslation();

  const { thumbnailPosition, showProductImage, buyItNowBtn } = useQuickViewThemeSettings();
  const {
    images_info,
    title,
    vendor,
    options_with_values,
    variants,
    variant_id,
    original_title,
    original_images,
  } = productData || {};
  const thumbnailPositionSetting = thumbnailPosition === 'bottomCenter' ? 'horizontal' : 'vertical';

  const [thumbPosition, setThumbPosition] = useState<'vertical' | 'horizontal'>(
    isMobileWidth() ? 'horizontal' : thumbnailPositionSetting
  );
  const [selectedVariant, setSelectedVariant] = useState<ProductDataVariant | undefined>(
    variants?.[0]
  );

  const {
    generalSettings: { current_tags, addCollectionToProductUrl: hasCollection, no_image_url },
  } = useGeneralSettings();

  const imagesArray = (original_images ? original_images : images_info)?.map((item) => item.src);

  let imagesFiltered = imagesArray?.filter((image): image is string => !!image);
  imagesFiltered = imagesFiltered?.length ? imagesFiltered : new Array(1).fill(no_image_url);

  const isSoldOutVariantSelected = !selectedVariant?.available;

  const isSale = useMemo(() => {
    return (Number(selectedVariant?.compare_at_price) || 0) > (Number(selectedVariant?.price) || 0);
  }, [selectedVariant]);

  const salePercent = useMemo(() => {
    return isSale && selectedVariant?.compare_at_price
      ? Math.round(
          (((Number(selectedVariant.compare_at_price) || 0) -
            (Number(selectedVariant?.price) || 0)) *
            100) /
            Number(selectedVariant.compare_at_price)
        ) + '%'
      : undefined;
  }, [selectedVariant]);

  const saleAmount = useMemo(() => {
    return isSale
      ? (Number(selectedVariant?.compare_at_price) || 0) - (Number(selectedVariant?.price) || 0)
      : undefined;
  }, [selectedVariant]);

  const labelByTag = useMemo(() => {
    return productData?.tags
      ?.filter((item) => item.indexOf('pfs:label') !== -1)
      .map((item) => item.split('pfs:label-')[1]);
  }, [productData]);

  const {
    productImg: { elements: productImgElementsSettings, grid: imageGridElements },
  } = useProductItemThemeSettings();

  const hideOtherLabelsWhenSoldOut =
    productImgElementsSettings?.productSoldOutLabel?.hideOtherLabelsWhenSoldOut;

  const { props: productSaleLabelProps } = useConnectProductLabel('productSaleLabel');
  const { props: productSolsOutLabelProps } = useConnectProductLabel('productSoldOutLabel');
  const { props: productCustomLabelByTagProps } = useConnectProductLabel('productCustomLabelByTag');

  const renderCustomLabelByTag = () => {
    return labelByTag?.map((item, index) => (
      <ProductLabel
        key={`custom-label-${index}`}
        {...productCustomLabelByTagProps}
        labelType='customLabelByTag'
        labelText={item}
      />
    ));
  };

  const [addToCartState, setAddToCartState] = useState<AddToCartState>({
    isAdding: false,
    success: false,
    fail: false,
  });

  const atcButtonLabel = useMemo(() => {
    if (isSoldOutVariantSelected) return t('productItem.soldoutLabel');

    return addToCartState.isAdding
      ? t('productItem.atcAddingToCartBtnLabel')
      : addToCartState.success
      ? t('productItem.atcAddedToCartBtnLabel')
      : addToCartState.fail
      ? t('productItem.atcFailedToCartBtnLabel')
      : t('productItem.atcAvailableLabel');
  }, [isSoldOutVariantSelected, addToCartState]);

  const { props: atcBtnProps } = useConnectQuickViewButton('addToCart');
  const { props: buyItNowBtnProps } = useConnectQuickViewButton('buyItNow');

  const [quantityError, setQuantityError] = useState<boolean>(false);

  const [quantity, setQuantity] = useState<number | ''>(1);

  const selectedVariantOptionsMapToObject: Dict = useMemo(() => {
    return mapValues(
      keyBy(
        selectedVariant?.original_merged_options || selectedVariant?.merged_options,
        (item) => item.split(':')[0]
      ),
      (value) => value.split(':')[1]
    );
  }, [selectedVariant]);

  const handleChangeVariant = (option: string, value: string) => {
    const newVariantOption = update({ ...selectedVariantOptionsMapToObject }, option, () => value);

    const newVariantOptionMapToArray = keys(newVariantOption).map(
      (key) => `${key}:${newVariantOption[key]}`
    );

    const newVariant = variants?.find((item) =>
      isEqual(item.original_merged_options || item.merged_options, newVariantOptionMapToArray)
    );

    setSelectedVariant(
      newVariant || {
        available: false,
        id: '',
        price: undefined,
        merged_options: newVariantOptionMapToArray,
      }
    );
  };

  const handleChangeQuantity = (newQuantity: number | '') => {
    if (quantityError) setQuantityError(false);
    setQuantity(newQuantity);
  };

  const handleAddToCartByQuickView = () => {
    if (addToCartState?.isAdding) return;
    if (quantity === '' || quantity <= 0) {
      setQuantityError(true);
      return;
    }

    if (selectedVariant) {
      setAddToCartState((prev) => ({ ...prev, isAdding: true }));
      window.dispatchEvent(
        new CustomEvent('boost-sd-add-to-cart', {
          detail: {
            variantId: selectedVariant.id,
            quantity: quantity,
            onSuccess: () => {
              setTimeout(() => {
                handleCloseModalQuickView();
                setAddToCartState((prev) => ({
                  fail: false,
                  isAdding: false,
                  success: false,
                }));
              }, timeToShowResultAddToCart);
              setAddToCartState((prev) => ({ ...prev, isAdding: false, success: true }));
            },
            onFail: () => {
              setTimeout(() => {
                setAddToCartState((prev) => ({
                  fail: false,
                  isAdding: false,
                  success: false,
                }));
              }, timeToShowResultAddToCart);
              setAddToCartState((prev) => ({ ...prev, fail: true, isAdding: false }));
            },
          },
        })
      );
    }
  };

  const handleBuyItNow = () => {
    if (quantity === '' || quantity <= 0) {
      setQuantityError(true);
      return;
    }

    if (selectedVariant) window.location.href = `/cart/${selectedVariant.id}:${quantity}`;
  };

  useEffect(function updateThumbPosition() {
    const listener = () => {
      if (isMobileWidth()) {
        setThumbPosition('horizontal');
      } else {
        setThumbPosition(thumbnailPositionSetting);
      }
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  useEffect(() => {
    if (callbackFuncAppIntegration) {
      callbackFuncAppIntegration.forEach((callback: () => void) => callback?.());
    }
  }, [selectedVariant?.id]);

  return (
    <Modal
      hasCloseButton
      isOpen={!!isOpen}
      handleCloseModal={handleCloseModalQuickView}
      closeButtonModifiers={['as-close-modal']}
    >
      <CustomizableNode renderer={onRender} payload={productData}>
        <div className={clsNameMap.root()} product-id={productData?.id}>
          {!isMini && showProductImage && (
            <div className={clsNameMap.elm('product-image')}>
              <Slider
                thumbs={{
                  enable: true,
                  thumbsOptions: { direction: thumbPosition },
                }}
                slides={imagesFiltered}
                onSlideRender={(item, index) => (
                  <ProductImage
                    key={index}
                    images={item}
                    aspectRatio='aspect-ratio-3-4'
                    altImage={title}
                    elements={{
                      saleLabel:
                        isSale &&
                        productSaleLabelProps &&
                        !(isSoldOutVariantSelected && hideOtherLabelsWhenSoldOut) ? (
                          <ProductLabel
                            key='sale-label'
                            {...productSaleLabelProps}
                            labelType='sale'
                            labelText={
                              <TranslateWithComponent
                                translation='productItem.productItemSale'
                                data={{
                                  salePercent,
                                  saleAmount: renderToString(<FormatCurrency value={saleAmount} />),
                                }}
                              />
                            }
                          />
                        ) : undefined,
                      soldOutLabel:
                        isSoldOutVariantSelected && productSolsOutLabelProps ? (
                          <ProductLabel
                            key='sold-out-label'
                            {...productSolsOutLabelProps}
                            labelType='soldout'
                            labelText={t('productItem.productItemSoldOut')}
                          />
                        ) : undefined,
                      customLabelByTag:
                        productCustomLabelByTagProps &&
                        !(isSoldOutVariantSelected && hideOtherLabelsWhenSoldOut) ? (
                          <Fragment key='custom-label'>{renderCustomLabelByTag()}</Fragment>
                        ) : undefined,
                    }}
                    grid={imageGridElements}
                    deviceSizes={[]}
                    imageSizes={[]}
                  />
                )}
              />
            </div>
          )}
          <div className={mapModifiers(clsNameMap['product-info'], { 'in-qv-mini': isMini })}>
            <h2 className={clsNameMap.elm('title')}> {variant_id ? original_title : title}</h2>
            <p className={clsNameMap.elm('vendor')}> {vendor}</p>
            <IntegrationProductRating />
            <div className={clsNameMap.elm('price')}>
              <FormatCurrency isSale={isSale} value={selectedVariant?.price} />
              {isSale && (
                <FormatCurrency isPriceCompare value={selectedVariant?.compare_at_price} />
              )}
            </div>
            {options_with_values?.map((option, index: number) => (
              <ProductSwatch
                options={option.values.map((value) => ({
                  value: value.origin_title || value.title,
                  label: value.title,
                  id: `product-swatch-qv-${productData?.id}-${value.title}`,
                  labelAs:
                    (option.original_name?.toLowerCase() || option.name) === 'color'
                      ? 'image'
                      : 'text',
                  displayType:
                    (option.original_name?.toLowerCase() || option.name) === 'size'
                      ? 'box'
                      : 'circle',
                  showOptionValueOnHovering:
                    (option.original_name?.toLowerCase() || option.name) === 'color',
                }))}
                key={index}
                onSelect={(value) =>
                  handleChangeVariant(option.original_name?.toLowerCase() || option.name, value)
                }
                label={option.label}
                defaultSelectedValue={option.values[0].title}
                isQuickView
              />
            ))}
            <div className={clsNameMap.elm('quantity')}>
              <p className={clsNameMap.elm('quantity-title')}>{t('quickView.qvQuantity')}</p>
              <Quantity error={quantityError} value={quantity} onChange={handleChangeQuantity} />
            </div>
            <div className={clsNameMap.elm('btn')}>
              <QuickViewButton
                {...atcBtnProps}
                text={atcButtonLabel}
                onClick={handleAddToCartByQuickView}
                disabled={isSoldOutVariantSelected}
                className={classnames('btn-add-to-cart')}
              />
              {buyItNowBtn?.enable && !isSoldOutVariantSelected && (
                <QuickViewButton
                  {...buyItNowBtnProps}
                  onClick={handleBuyItNow}
                  text={t('quickView.buyItNowBtnLabel')}
                  className={classnames('btn-buy-now')}
                />
              )}
            </div>
            <a
              className={clsNameMap.elm('details-link')}
              href={buildProductDetailUrlWithVariant(
                productData || {},
                hasCollection,
                current_tags
              )}
            >
              {t('quickView.qvViewFullDetails')}
            </a>
          </div>
        </div>
      </CustomizableNode>
    </Modal>
  );
};

export default registryComponent('QuickView', QuickView);
