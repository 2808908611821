import type { ProductLabelProps } from '@components/ProductLabel';
import { useProductItemThemeSettings } from '@providers/ThemeProvider/Provider/ProductItemThemeSettings';
import type { Dict } from '@types';
import { useMemo } from 'react';

export type ProductLabelPropsMapSettings = Omit<
  ProductLabelProps,
  'onRender' | 'imgAttrs' | 'imgAlt' | 'labelType'
>;

export const useConnectProductLabel = (
  type: 'productSaleLabel' | 'productSoldOutLabel' | 'productCustomLabelByTag'
) => {
  const settings = useProductItemThemeSettings();

  const productLabelSettings = settings.productImg.elements?.[type];

  const props: ProductLabelPropsMapSettings | Dict = useMemo(() => {
    return {
      ...productLabelSettings,
    };
  }, [productLabelSettings]);

  return {
    props,
  };
};
