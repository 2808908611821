import type { ProductListItem } from '@providers/ProductListProvider';
import type { Dict } from '@types';
import { merge } from 'lodash-es';

export type GeneraSettingsType = Dict & {
  collection_handle: string;
  collection_id: number;
  collection_tags?: null | Array<string>;
  currencies: Array<string>;
  current_currency: string;
  current_locale: string;
  current_tags?: null | Array<string>;
  default_sort_by: string;
  file_url: string;
  no_image_url: string;
  published_locales: Dict<boolean>;
  theme_id: number;
  addCollectionToProductUrl: boolean;
  custom_js_asset_url?: string;
  custom_css_asset_url?: string;
  priceSettings?: {
    saleCondition?: (product: ProductListItem) => boolean;
  };
  termKey: string;
};

const defaultSettings: GeneraSettingsType = {
  collection_handle: '',
  collection_id: 0,
  collection_tags: null,
  currencies: ['USD'],
  current_currency: 'USD',
  current_locale: 'en',
  current_tags: null,
  default_sort_by: '',
  file_url: '//cdn.shopify.com/s/files/1/0663/1463/3470/files/?10045',
  no_image_url:
    'https://cdn.shopify.com/extensions/201f4d7b-8d39-43a4-82de-e6f412341695/0.0.0/assets/boost-pfs-no-image.jpg?v=1677838268',
  published_locales: { en: true },
  theme_id: 0,
  addCollectionToProductUrl: true,
  animationClassesNewVersion: '.animate--slide-in', // default class animation new version effect sticky feature ex: .animate--slide-in, .animation--fade-in
  termKey: 'q',
};
/**
 * Add new settings for customizeSearchParams -> apply Instant Search and Filter in Search Page
 * customizeSearchParams: Dict
 */
const useGeneralSettings = () => {
  const generalSettings: GeneraSettingsType = merge(
    defaultSettings,
    window.boostSDAppConfig?.generalSettings || {}
  );

  const fileUrl: string = generalSettings.file_url;

  const fileBaseUrl: string = fileUrl?.split('?')[0] || '';

  return { generalSettings, fileBaseUrl };
};

export default useGeneralSettings;
